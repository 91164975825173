<template>
  <div>
    <v-container fluid fill-height>
      <c-ecosystem-nav :ecosystemId="ecosystemId"></c-ecosystem-nav>

      <v-row class="align-self-center my-6 formCenter">
        <v-col>
          <v-card flat class="text-center">
            <c-page-heading
              :heading="$t('ecosystems.view.gatherings')"
              :subHeading="$t('ecosystems.view.administration')"
            ></c-page-heading>

            <c-panels :ecosystemId="ecosystemId" files="true"></c-panels>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  props: ['ecosystemId'],
}
</script>
<style scoped></style>
